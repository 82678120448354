import { autoinject, bindable } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import {
  DeleteSiteChannels,
  DeleteSiteChannelsVariables,
  UpdateSiteChannels,
  UpdateSiteChannelsVariables
} from '../../../../../../custom_typings/graphql';
import { ChannelTypes, SecurityLevels } from '../../../../../config';
import {
  ChannelTypes as ChannelTypesEnum,
  IProduct,
  ISiteChannel,
  IUnit
} from '../../../../../interfaces';
import { rootState } from '../../../../../reducers';
import { ChannelService } from '../../../../../services';
import {
  distinct,
  emptyArray,
  getAsyncEntity,
  getEntityOrUndefined,
  isSomething,
  removeNoneFromArray
} from '../../../../../utility';
import { getSiteIdParameter, GraphQLBaseViewModel } from '../../../../common';
import './sitedetialschannelsmultiedit.css';

interface ISiteChannelWithProductAndUnit extends ISiteChannel {
  product: IProduct | undefined;
  unit: IUnit | undefined;
}

interface IFormValue {
  productId?: number;
  securityLevel?: number;
  unitId?: number;
  channelType?: number;
  parked?: boolean;
  capacity?: number;
  maximum?: number;
  minimum?: number;
}

interface ISelectedSiteChannelsData {
  products: IProduct[];
  securityLevels: number[];
  units: IUnit[];
  types: ChannelTypesEnum[];
  capacities: number[];
  maximums: number[];
  minimums: number[];
}

interface MultiEditChannelState {
  siteChannels: ISiteChannelWithProductAndUnit[];
}

enum FormValueEnum {
  productId = 'productId',
  securityLevel = 'securityLevel',
  unitId = 'unitId',
  channelType = 'channelType',
  parked = 'parked',
  capacity = 'capacity',
  maximum = 'maximum',
  minimum = 'minimum'
}

const mapState = (channelService: ChannelService) => (
  state: rootState
): MultiEditChannelState => {
  const siteId = getSiteIdParameter(state).valueOr(0);

  const siteChannels = channelService
    .getSiteChannelsForSite(siteId)
    .getEntityOrDefault(emptyArray)
    .map(channel => {
      return {
        ...channel,
        product: getEntityOrUndefined(
          getAsyncEntity(state.products.byId, channel.productId)
        ),
        unit: getEntityOrUndefined(
          getAsyncEntity(state.units.byId, channel.unitId)
        )
      };
    });

  return {
    siteChannels
  };
};

@autoinject()
export class MultiEditChannel extends GraphQLBaseViewModel<
  MultiEditChannelState,
  void,
  void
> {
  constructor(channelService: ChannelService) {
    super(getLogger('multi-edit-channel'), mapState(channelService));
  }

  @bindable selectedSiteChannelIds: number[];
  @bindable onSuccess: () => {};
  @bindable remoteChannelSelected: boolean

  deleteChannelsConfirmation = false;
  showEditDialog = false;
  formValue: IFormValue = {};
  securityLevels = SecurityLevels;
  channelTypes = ChannelTypes;

  pendingChanges = {
    productId: false,
    securityLevel: false,
    unitId: false,
    channelType: false,
    parked: false,
    capacity: false,
    maximum: false,
    minimum: false
  };

  selectedSiteChannelsData: ISelectedSiteChannelsData = {
    products: [],
    securityLevels: [],
    units: [],
    types: [],
    capacities: [],
    maximums: [],
    minimums: []
  };

  selectedSiteChannelIdsChanged() {
    const selectedSiteChannels = this.state.siteChannels.filter(sc => this.selectedSiteChannelIds.includes(sc.siteChannelId) );
    this.mapToSelectedSiteChannelsData(selectedSiteChannels);
  }

  mapToSelectedSiteChannelsData = (
    siteChannels: ISiteChannelWithProductAndUnit[]
  ) => {
    this.selectedSiteChannelsData.products = distinct(
      removeNoneFromArray(siteChannels.map(s => s.product))
    );
    this.selectedSiteChannelsData.securityLevels = distinct(
      siteChannels.map(s => s.securityLevel)
    );
    this.selectedSiteChannelsData.units = distinct(
      removeNoneFromArray(siteChannels.map(s => s.unit))
    );
    this.selectedSiteChannelsData.types = distinct(
      removeNoneFromArray(siteChannels.map(s => s.channelType))
    );
    this.selectedSiteChannelsData.capacities = distinct(
      removeNoneFromArray(siteChannels.flatMap(s => s.capacity))
    );
    this.selectedSiteChannelsData.maximums = distinct(
      removeNoneFromArray(siteChannels.map(s => s.maximum))
    );
    this.selectedSiteChannelsData.minimums = distinct(
      removeNoneFromArray(siteChannels.map(s => s.minimum))
    );
  };

  deleteChannels = async () => {
    const variables: DeleteSiteChannelsVariables = {
      siteChannelIds: this.selectedSiteChannelIds
    };

    await this.runQuery<DeleteSiteChannels, DeleteSiteChannelsVariables>(
      gql`
        mutation DeleteSiteChannels($siteChannelIds: [Int!]!) {
          deleteSiteChannels(siteChannelIds: $siteChannelIds)
        }
      `,
      variables
    );

    this.onSuccess();
    this.revalidateAllActiveQueries();
    this.deleteChannelsConfirmation = false;
  };

  saveChanges = async () => {
    const variables: UpdateSiteChannelsVariables = {
      siteChannelIds: this.selectedSiteChannelIds.filter(isSomething),
      ...this.formValue
    };

    await this.runQuery<UpdateSiteChannels, UpdateSiteChannelsVariables>(
      gql`
        mutation UpdateSiteChannels(
          $siteChannelIds: [Int!]!
          $productId: Int
          $securityLevel: Int
          $unitId: Int
          $channelType: Int
          $parked: Boolean
          $capacity: Float
          $maximum: Float
          $minimum: Float
        ) {
          updateSiteChannels(
            siteChannelIds: $siteChannelIds
            productId: $productId
            securityLevel: $securityLevel
            unitId: $unitId
            channelType: $channelType
            parked: $parked
            capacity: $capacity
            maximum: $maximum
            minimum: $minimum
          ) {
            siteChannelId            
          }
        }
      `,
      variables
    );

    this.onSuccess();
    this.revalidateAllActiveQueries();
    this.showEditDialog = false;
  };

  cancelChanges = () => {
    this.showEditDialog = false;
    this.formValue = {};
    this.pendingChanges = {
      productId: false,
      securityLevel: false,
      unitId: false,
      channelType: false,
      parked: false,
      capacity: false,
      maximum: false,
      minimum: false
    };
  };

  changeForm = (formValue: FormValueEnum, value: number | boolean | string) => {
    switch (formValue) {
      case FormValueEnum.productId:
        if (typeof value === 'string') this.formValue.productId = +value;
        if (typeof value === 'undefined') this.formValue.productId = undefined;
        break;
      case FormValueEnum.securityLevel:
        if (typeof value === 'number') this.formValue.securityLevel = value;
        if (typeof value === 'undefined')
          this.formValue.securityLevel = undefined;
        break;
      case FormValueEnum.unitId:
        if (typeof value === 'string') this.formValue.unitId = +value;
        if (typeof value === 'undefined') this.formValue.unitId = undefined;
        break;
      case FormValueEnum.channelType:
        if (typeof value === 'number') this.formValue.channelType = value;
        if (typeof value === 'undefined')
          this.formValue.channelType = undefined;
        break;
      case FormValueEnum.parked:
        if (typeof value === 'boolean' && this.formValue.parked === value) {
          this.formValue.parked = undefined;
        } else if (typeof value === 'boolean') {
          this.formValue.parked = value;
        }
        break;
      case FormValueEnum.capacity:
        if (typeof value === 'string' && value.length === 0)
          this.formValue.capacity = undefined;
        else if (typeof value === 'string') this.formValue.capacity = +value;
        break;
      case FormValueEnum.maximum:
        if (typeof value === 'string' && value.length === 0)
          this.formValue.maximum = undefined;
        else if (typeof value === 'string') this.formValue.maximum = +value;
        break;
      case FormValueEnum.minimum:
        if (typeof value === 'string' && value.length === 0)
          this.formValue.minimum = undefined;
        else if (typeof value === 'string') this.formValue.minimum = +value;
        break;
    }
  };

  changeCheckbox = (formValue: FormValueEnum, value: boolean) => {
    switch (formValue) {
      case FormValueEnum.productId:
        this.pendingChanges.productId = value;
        if (!value) this.formValue.productId = undefined;
        break;
      case FormValueEnum.securityLevel:
        this.pendingChanges.securityLevel = value;
        if (!value) this.formValue.securityLevel = undefined;
        break;
      case FormValueEnum.unitId:
        this.pendingChanges.unitId = value;
        if (!value) this.formValue.unitId = undefined;
        break;
      case FormValueEnum.channelType:
        this.pendingChanges.channelType = value;
        if (!value) this.formValue.channelType = undefined;
        break;
      case FormValueEnum.parked:
        this.pendingChanges.parked = value;
        if (!value) this.formValue.parked = undefined;
        break;
      case FormValueEnum.capacity:
        this.pendingChanges.capacity = value;
        if (!value) this.formValue.capacity = undefined;
        break;
      case FormValueEnum.maximum:
        this.pendingChanges.maximum = value;
        if (!value) this.formValue.maximum = undefined;
        break;
      case FormValueEnum.minimum:
        this.pendingChanges.minimum = value;
        if (!value) this.formValue.minimum = undefined;
        break;
    }
  };
}
