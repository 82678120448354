import {
  BaseViewModel,
  getSiteIdParameter,
  selectSortedSites,
  getTimezoneForSiteSelector
} from '../../../common';
import {
  toObject,
  getEntityOrDefault,
  getAsyncEntity,
  emptyArray,
  getNewId,
  getAsyncEntitiesByAsyncArray,
  isNone,
  exitMaybe,
  getEntityOrUndefined,
  userHasFeature,
  orderByPredicate,
  mapFetchedAsyncEntity,
  isSomething,
  distinct,
  leftPadNumber,
  ensureDate
} from '../../../../utility/index';
import { rootState } from '../../../../reducers/index';
import {
  UnitService,
  ControllerService,
  ChannelService,
  ProductService,
  SiteService,
  ExportJobService
} from '../../../../services/index';
import { getLogger } from 'aurelia-logging';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import {
  ISiteChannel,
  ISite,
  IController,
  routes,
  ISiteDetailsChannelsRoute,
  IProduct,
  IUnit,
  AccessLevel
} from '../../../../interfaces/index';
import { menuIsOpenForEntity, sortDirection } from '../../../../types/index';
import {
  toggleExpandMenuOnEntity,
  addChannelPropertyChanged,
  siteChannelSortingChanged,
  setChannelOrderMode,
  multiEditSiteChannels,
  toggleHideStaleChannels
} from '../../../../actions/index';
import {
  ChannelTypes,
  routeNames,
  features,
  SecurityLevels
} from '../../../../config';
import { Maybe } from 'tsmonad';
import { Router } from 'aurelia-router';
import { getSession } from '../../../../config/sessionService';
import { ISiteExport } from '../../../../interfaces/entity/iSiteExport';
import './sitedetailschannels.css';
import { ISiteAccess } from '../../../../interfaces/application/iSiteAccess';

interface ISiteDetailsChannelState {
  siteId: number;
  siteChannelId: number | undefined;
  siteChannels: ISiteDetailsChannelChannel[];
  hasMultipleControllers: boolean;
  sites: ISite[];
  sitesMap: {};
  channelTypes: typeof ChannelTypes;
  nativeControllers: IController[];
  menuIsOpenForEntity?: menuIsOpenForEntity;
  currentlyAddingChannel?: {};
  showMobile: boolean;
  securityLevels: typeof SecurityLevels;
  channelEditType: 'siteChannel' | 'remoteSiteChannel';
  channelEditAction: 'edit' | 'create';
  access: {
    canWrite: boolean;
    canReadExportJobs: boolean;
    canNavigateToController: boolean;
  };
  sortDirection: sortDirection;
  sortProperty: string;
  changeChannelOrderMode: boolean;
  changeChannelOrderModeRequest: 'failed' | 'current' | undefined;
  timezone?: string;
  hideStaleChannels: boolean;
}

const routeIsSiteDetailsChannels = (
  route: routes
): route is ISiteDetailsChannelsRoute =>
  route.route === routeNames.sitedetailsChannels;

const getChannelUrlFromState = (
  route: routes
): Maybe<ISiteDetailsChannelsRoute> =>
  routeIsSiteDetailsChannels(route)
    ? Maybe.just(route)
    : route.child
    ? getChannelUrlFromState(route.child)
    : Maybe.nothing<ISiteDetailsChannelsRoute>();

const getChannelIdFromState = (route: routes): Maybe<number> =>
  getChannelUrlFromState(route)
    .bind(r =>
      r.params.siteChannelId
        ? Maybe.just(r.params.siteChannelId)
        : Maybe.nothing<string>()
    )
    .map(r => parseInt(r, 10));

const getChannelTypeFromState = (
  route: routes
): Maybe<'siteChannel' | 'remoteSiteChannel'> =>
  getChannelUrlFromState(route)
    .bind(r =>
      r.params.type
        ? Maybe.just(r.params.type)
        : Maybe.nothing<'remote' | 'owner'>()
    )
    .map(r => (r === 'remote' ? 'remoteSiteChannel' : 'siteChannel'));

const getChannelActionFromState = (route: routes): Maybe<'edit' | 'create'> =>
  getChannelUrlFromState(route).map(t => t.params.action || 'edit');

export interface ISiteDetailsChannelChannel extends ISiteChannel {
  controller: IController | undefined;
  remoteChannelStatus:
    | 'remoteWithSiteAccess'
    | 'remoteNoSiteAccess'
    | 'notRemote'
    | undefined;
  product: IProduct | undefined;
  unit: IUnit | undefined;
  exports: ISiteExport[];
}

const orderSiteChannels = (
  siteChannels: ISiteDetailsChannelChannel[],
  property: string,
  direction: sortDirection
): ISiteDetailsChannelChannel[] => {
  switch (property) {
    case 'alias':
      return orderByPredicate(siteChannels, sc => sc.alias, direction);
    case 'channelId':
      return orderByPredicate(siteChannels, sc => sc.channelId, direction);
    case 'channelType': // TODO: Use translated channeltype
      return orderByPredicate(siteChannels, sc => sc.channelType, direction);
    case 'controllerName':
      return orderByPredicate(
        siteChannels,
        sc => sc.controller && sc.controller.alias,
        direction
      );
    case 'unit':
      return orderByPredicate(
        siteChannels,
        sc => sc.unit && sc.unit.symbol,
        direction
      );
    case 'capacity':
      return orderByPredicate(siteChannels, sc => sc.capacity, direction);
    case 'maximum':
      return orderByPredicate(siteChannels, sc => sc.maximum, direction);
    case 'minimum':
      return orderByPredicate(siteChannels, sc => sc.minimum, direction);
    case 'lastSampleTime':
      return orderByPredicate(siteChannels, sc => sc.lastSampleTime, direction);
    case 'lastSample':
      return orderByPredicate(siteChannels, sc => sc.lastSample, direction);
    case 'isParked':
      return orderByPredicate(siteChannels, sc => sc.isParked, direction);
    case 'sortIndex':
      return orderByPredicate(siteChannels, sc => sc.sortIndex, direction);
    case 'securityLevel':
      return orderByPredicate(siteChannels, sc => sc.securityLevel, direction);
    case 'code':
      return orderByPredicate(siteChannels, sc => sc.code, direction);
    case 'product':
      return orderByPredicate(
        siteChannels,
        sc => sc.product && sc.product.name,
        direction
      );
  }
  return siteChannels;
};

const getChannelIsRemoteStatus = (
  siteId: number,
  controller: IController | undefined,
  siteAccesses: ISiteAccess[]
): 'notRemote' | 'remoteWithSiteAccess' | 'remoteNoSiteAccess' => {
  const channelIsRemote =
    isSomething(controller) && controller.siteId !== siteId;
  const siteAccess = isSomething(controller)
    ? siteAccesses.find(sa => sa.siteId === controller.siteId)
    : undefined;

  if (!channelIsRemote) return 'notRemote';
  return isSomething(siteAccess) && siteAccess.hasAccess
    ? 'remoteWithSiteAccess'
    : 'remoteNoSiteAccess';
};

const mapState = (
  channelService: ChannelService,
  controllerService: ControllerService,
  siteService: SiteService
) => (state: rootState): ISiteDetailsChannelState => {
  const siteId = getSiteIdParameter(state).valueOr(0);

  const timezone = getTimezoneForSiteSelector(state);

  const asyncControllers = controllerService.getControllersBySiteId(siteId);
  const exportJobs = getEntityOrDefault(
    getAsyncEntitiesByAsyncArray(
      getAsyncEntity(state.siteExports.bySiteId, siteId),
      state.siteExports.byId
    ),
    emptyArray
  );

  const { sitedetailsChannels } = state;

  const pendingChannelOrders = sitedetailsChannels.pendingChannelOrders;
  const sortProperty = pendingChannelOrders
    ? 'sortIndex'
    : sitedetailsChannels.sortProperty;
  const sortDirection = pendingChannelOrders
    ? 'asc'
    : sitedetailsChannels.sortDirection;

  const siteAccessesFetcher = siteService.getSiteAccessForSiteChannelsOnSite(
    siteId
  );

  const siteChannels = channelService
    .getSiteChannelsForSite(siteId)
    .map3(
      asyncControllers,
      siteAccessesFetcher,
      (channels, controllers, siteAccesses) =>
      orderByPredicate(channels, c => leftPadNumber(c.sortIndex || 0, 3) + "_" + c.alias, 'asc')
        .map<ISiteDetailsChannelChannel>((channel, index) => {
          const controller = controllers.find(
            c => c.controllerId === channel.controllerId
          );

          return {
            ...channel,
            controller,
            remoteChannelStatus: getChannelIsRemoteStatus(
              siteId,
              controller,
              siteAccesses
            ),
            product: getEntityOrUndefined(
              getAsyncEntity(state.products.byId, channel.productId)
            ),
            unit: getEntityOrUndefined(
              getAsyncEntity(state.units.byId, channel.unitId)
            ),
            exports: exportJobs.filter(e =>
              e.siteChannelIds.includes(channel.siteChannelId)
            ),
            sortIndex: pendingChannelOrders
            ? pendingChannelOrders.indexOf(channel.siteChannelId)
            : index
          };
        })
    )
    .map(scs => orderSiteChannels(scs, sortProperty, sortDirection))
    .getEntityOrDefault(emptyArray);

  const sites = getEntityOrDefault(selectSortedSites(state), emptyArray);
  const sitesMap = toObject(sites, s => s.siteId);

  const selectedSiteId =
    (state.sitedetailsChannels.currentlyAddingChannel &&
      state.sitedetailsChannels.currentlyAddingChannel.selectedSiteId) ||
    undefined;
  const selectedChannelId =
    state.sitedetailsChannels.currentlyAddingChannel &&
    state.sitedetailsChannels.currentlyAddingChannel.selectedChannelId;
  const asyncSiteControllerChannels = getAsyncEntitiesByAsyncArray(
    getAsyncEntity(state.channels.siteChannelsForSites, selectedSiteId),
    state.channels.byId
  );
  const siteControllersChannels = getEntityOrDefault(
    asyncSiteControllerChannels,
    emptyArray
  );

  const showMobile = state.device.screenSize === 'mobile';

  const filterByStale = (hideStaleChannels: boolean, siteChannels: ISiteDetailsChannelChannel[]) => {
    if (!hideStaleChannels)
      return siteChannels;
  
    const sampleTimes = siteChannels.map(sc => ensureDate(sc.lastSampleTime).getTime());
    const maxLastSampleTime = new Date(Math.max(...sampleTimes));
    maxLastSampleTime.setDate(maxLastSampleTime.getDate() - 1);
  
    return siteChannels.filter(sc => ensureDate(sc.lastSampleTime) > maxLastSampleTime);
  }  

  return {
    hideStaleChannels: sitedetailsChannels.hideStaleChannels,
    siteId,
    siteChannelId: exitMaybe(getChannelIdFromState(state.router.currentRoute)),
    sites,
    sitesMap,
    siteChannels: filterByStale(sitedetailsChannels.hideStaleChannels, siteChannels),
    nativeControllers: asyncControllers
      .map(controllers =>
        controllers.filter(controller => controller.siteId === siteId)
      )
      .getEntityOrDefault(emptyArray),
    channelTypes: ChannelTypes,
    hasMultipleControllers:
      distinct(siteChannels.map(c => c.controllerId)).length > 1,
    menuIsOpenForEntity: state.application.menuIsOpenForEntity,
    currentlyAddingChannel: {
      channels: siteControllersChannels.filter(
        cc => !siteChannels.some(sc => sc.channelId === cc.channelId)
      ),
      channel: siteControllersChannels.find(
        c => c.channelId === selectedChannelId
      ),
      siteId: selectedSiteId,
      site: mapFetchedAsyncEntity(selectSortedSites(state), ss =>
        ss.find(s => s.siteId === selectedSiteId)
      ),
      channelId: selectedChannelId
    },
    showMobile,
    channelEditAction: getChannelActionFromState(
      state.router.currentRoute
    ).valueOr('edit'),
    channelEditType: getChannelTypeFromState(state.router.currentRoute).valueOr(
      'siteChannel'
    ),
    access: {
      canWrite: userHasFeature(
        getSession(),
        features.siteDetailsChannels,
        AccessLevel.Write
      ),
      canReadExportJobs: userHasFeature(
        getSession(),
        features.exportJobChannels,
        AccessLevel.Read
      ),
      canNavigateToController: userHasFeature(
        getSession(),
        features.siteDetailsController,
        AccessLevel.Read
      )
    },
    securityLevels: SecurityLevels,
    sortProperty,
    sortDirection,
    changeChannelOrderMode: !!pendingChannelOrders,
    changeChannelOrderModeRequest:
      state.sitedetailsChannels.pendingChannelOrdersCurrentRequest,

    timezone
  };
};

@autoinject()
export class SiteDetailsChannels extends BaseViewModel<
  ISiteDetailsChannelState
> {
  productDropdownId = getNewId();
  unitDropdownId = getNewId();

  showAddChannelDialog = false;
  remoteChannelSelected = false;
  @observable selectedRows: number[] = [];

  constructor(
    private controllerService: ControllerService,
    private channelService: ChannelService,
    private unitService: UnitService,
    private productService: ProductService,
    private siteService: SiteService,
    private router: Router,
    private exportJobService: ExportJobService
  ) {
    super(
      getLogger('SiteDetailsChannels'),
      mapState(channelService, controllerService, siteService),
      state =>
        getSiteIdParameter(state)
          .map(_ => true)
          .valueOr(false)
    );
  }

  toggleHideStaleChannels = () => {    
    this.dispatch(toggleHideStaleChannels());
  }
  
  onSuccess = () => {
    this.dispatch(multiEditSiteChannels(this.state.siteId));
    this.clearRowSelections();
  };

  afterDragged = (siteChannels: ISiteDetailsChannelChannel[]) =>
    this.dispatch(
      setChannelOrderMode(siteChannels.map(sc => sc.siteChannelId))
    );

  toggleChannelOrderMode = () => {
    const { changeChannelOrderMode, siteChannels } = this.state;
    const payload = changeChannelOrderMode
      ? undefined
      : orderByPredicate(siteChannels, s => s.sortIndex, 'asc').map(
          sc => sc.siteChannelId
        );
    this.dispatch(setChannelOrderMode(payload));
  };

  reverseSortDirection = (sortDirection: sortDirection): sortDirection =>
    sortDirection === 'asc' ? 'desc' : 'asc';

  sortByProperty = (sortProperty: string) => {
    if (sortProperty !== this.state.sortProperty)
      this.dispatch(
        siteChannelSortingChanged({ sortProperty, sortDirection: 'asc' })
      );
    else
      this.dispatch(
        siteChannelSortingChanged({
          sortProperty,
          sortDirection: this.reverseSortDirection(this.state.sortDirection)
        })
      );
  };

  saveChannelOrderMode = () => {
    this.channelService.updateSiteChannelsOrder(
      orderByPredicate(this.state.siteChannels, s => s.sortIndex, 'asc')
    );
  };

  activate() {
    this.unitService.fetchUnitsAsync();
    this.productService.getAllAsync();
    if (this.state.access.canReadExportJobs)
      this.exportJobService.getExportedChannelsForSiteAsync(this.state.siteId);

    this.clearRowSelections();
  }

  toggleAddChannelDialog = async () => {
    this.showAddChannelDialog = !this.showAddChannelDialog;

    if (this.showAddChannelDialog) this.siteService.getAllSitesAsync();
  };

  createChannel = (controllerId: number) =>
    this.router.navigateToRoute(routeNames.sitedetailsChannels, {
      siteChannelId: controllerId,
      action: 'create'
    });

  loadChannelsBySite = async (siteId: number) => {
    await this.channelService.getSiteChannelsForSiteAsync(siteId);

    this.dispatchAddChannelPropertyChanged('selectedSiteId', siteId);
  };

  navigateToChannelThroughEditor = (channelId: number) => {
    const matchingSiteChannel = this.state.siteChannels.find(
      siteChannel => siteChannel.channelId === channelId
    );
    if (!matchingSiteChannel) return;
    this.router.navigateToRoute(routeNames.sitedetailsChannels, {
      siteChannelId: matchingSiteChannel.siteChannelId,
      type: 'owner',
      action: 'edit'
    });
  };

  canNavigateToChannelThroughEditor = (channelId: number) => {
    const matchingSiteChannel = this.state.siteChannels.find(
      siteChannel => siteChannel.channelId === channelId
    );
    return !!matchingSiteChannel;
  };

  addSiteChannel = async (siteId: number, channelId: number) => {
    if (siteId > 0 && channelId > 0) {
      await this.channelService.createSiteChannel(siteId, channelId);
      await this.controllerService.getControllersBySiteIdAsync(siteId, false);
      this.showAddChannelDialog = false;
    } else return;
  };

  editChannel({ siteChannelId }: ISiteDetailsChannelChannel) {
    if (!this.state.changeChannelOrderMode) {
      this.router.navigateToRoute(routeNames.sitedetailsChannels, {
        siteChannelId: siteChannelId,
        type: 'owner',
        action: 'edit'
      });
    }
  }

  getLink(item: ISiteDetailsChannelChannel) {
    if (this.state.changeChannelOrderMode) return undefined; // #3014

    if (
      item.remoteChannelStatus === 'remoteNoSiteAccess' ||
      item.remoteChannelStatus === 'remoteWithSiteAccess'
    )
      return `/sitedetails/${item.siteId}/channels/edit/${item.siteChannelId}/remote`;
    else
      return `/sitedetails/${item.siteId}/channels/edit/${item.siteChannelId}/owner`;
  }

  navigateBackFromEdit = () => {
    if (this.state.showMobile) this.router.navigateBack();
    else
      this.router.navigateToRoute(routeNames.sitedetailsChannels, undefined, {
        replace: true
      });
  };
  dispatchAddChannelPropertyChanged = (
    property: string,
    value: string | number
  ) => this.dispatch(addChannelPropertyChanged({ property, value }));
  dispatchMenuIsOpenForEntity = (entityId: number) =>
    this.dispatch(toggleExpandMenuOnEntity(entityId));
  isNone = isNone;

  selectedRowsChanged() {
    const remoteChannelSelected = this.state?.siteChannels
      .filter(sc => this.selectedRows.includes(sc.siteChannelId))
      .some(sc => sc.controller?.siteId !== sc.siteId);
    this.remoteChannelSelected = remoteChannelSelected;
  }

  isSelected(key: number, selectedRows: number[]) {
    return selectedRows.includes(key);
  }

  @computedFrom('selectedRows', 'state.siteChannels')
  get isAllSelected() {
    const selectableRows = this.state.siteChannels.map(s => s.siteChannelId);
    if (!selectableRows || selectableRows.length < 1) return false;
    return selectableRows.every(r => this.selectedRows.includes(r));
  }

  toggleSelectedRow(rowSiteChannelId: number) {
    if (this.selectedRows.includes(rowSiteChannelId)) {
      this.selectedRows = this.selectedRows.filter(f => f !== rowSiteChannelId);
    } else {
      this.selectedRows = [...this.selectedRows, rowSiteChannelId];
    }
  }

  clearRowSelections() {
    this.selectedRows = [];
  }

  async toggleAllRows(setToChecked: boolean) {
    const selectableRows = this.state.siteChannels.map(sc => sc.siteChannelId);
    if (setToChecked)
      this.selectedRows = distinct([...this.selectedRows, ...selectableRows]);
    else
      this.selectedRows = this.selectedRows.filter(
        f => !selectableRows?.includes(f)
      );
  }
}
